type Alert = any;
import { ResourceType } from "@milio/lib/data/constant";
import { RuleID, RuleIDSchema } from "@milio/lib/data/rule/constant";
import { RuleSchema } from "@milio/lib/data/rule/type";

import * as R from "ramda";
import React from "react";
import pluralize from "pluralize";

import { Title, Paragraph, Link, signToColor } from "components/library";
import { getCohortLink } from "util/link";
import { useRouter } from "next/router";
import { useClientCohortProvider } from "hooks/useClientCohortProvider";

export interface AlertDescriptionProps {
  alert: Alert;
}

interface SpecializationProps {
  alert: Alert;
  schema: RuleSchema;
}

interface ResourceCountProps {
  alert: Alert;
  count: number;
  resource_label: string;
  resource_type: ResourceType;
  /** Label to use in the page title for links. */
  view_label?: string;
}

const AlertName: React.FC<{ label: string }> = ({ label }) => {
  if (R.isNil(label)) {
    return null;
  }

  return (
    <>
      <Title>{label}</Title>
      {" — "}
    </>
  );
};

const ResourceCount: React.FC<ResourceCountProps> = ({
  alert,
  count,
  resource_label,
  resource_type,
  view_label,
}) => {
  const router = useRouter();

  const { createCohort } = useClientCohortProvider();
  const resources: string[] = R.path(
    ["metrics", "count", "context", "resources"],
    alert.data
  );

  const label = pluralize(resource_label, count);
  const delta = R.path(["data", "delta"], alert) as number;
  const sign: number = Math.sign(delta);
  const deltaColor: string = signToColor(sign);

  const launch = React.useCallback(async () => {
    const cohortId = await createCohort(...resources);
    router.push(getCohortLink(resource_type, cohortId, view_label));
  }, [alert]);

  return count > 0 ? (
    <>
      <Link onClick={launch}>
        {count} {label}
      </Link>{" "}
      {!R.isNil(delta) && delta !== 0 && (
        <span style={{ color: deltaColor }}>
          {"("}
          {sign >= 1 && <span>+</span>}
          {delta}
          {")"}
        </span>
      )}
    </>
  ) : (
    <InterestingInfo info={`${count} ${label}`} />
  );
};

const InterestingInfo: React.FC<{ info: string }> = ({ info }) => (
  <span className="font-semibold">{info}</span>
);

const CompaRatioOutliers: React.FC<SpecializationProps> = ({
  alert,
  schema,
}) => {
  const count = R.path<number>(
    ["metrics", "count", "value"],
    alert.data
  ) as number;
  const threshold = R.path<number>(
    ["metrics", "count", "context", "threshold"],
    alert.data
  ) as number;
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={`${schema.label}, ${Math.round(
          threshold * 100
        )}% away from average`}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` whose Compa-Ratio is more than ${Math.round(
        threshold * 100
      )}% away from the average Compa-Ratio among peers.`}
    </Paragraph>
  );
};

const FlightRisks: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` who are considered flight risks.`}
    </Paragraph>
  );
};

const GenderPayComparison: React.FC<SpecializationProps> = ({
  alert,
  schema,
}) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  const threshold = R.path<number>(
    ["metrics", "count", "context", "threshold"],
    alert.data
  );
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={`${schema.label}, ${Math.round(
          threshold * 100
        )}% away from average`}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` whose pay is more than ${Math.round(
        threshold * 100
      )}% away from the average among peers of another gender.`}
    </Paragraph>
  );
};

const MissingPaybands: React.FC<SpecializationProps> = ({ alert, schema }) => {
  // const count = R.path(['metrics', 'count', 'value'], alert.data);

  // Using this count for the display as it's less confusing due to rollup by title on the plan page.
  const countByJobTitle = R.pipe(
    R.path(["metrics", "count", "context", "resources"]),
    R.uniqBy(R.prop("job_title_id")),
    R.length
  )(alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        resource_type={ResourceType.JobTitle}
        resource_label="job title"
        count={countByJobTitle}
        alert={alert}
        view_label={schema.label}
      />
      {` with active employees that are missing paybands.`}
    </Paragraph>
  );
};

const ManagerChanges: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` changed managers.`}
    </Paragraph>
  );
};
const NewHires: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` joined.`}
    </Paragraph>
  );
};
const PayChanges: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` had pay changes.`}
    </Paragraph>
  );
};

const JobTitleChanges: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` changed job titles.`}
    </Paragraph>
  );
};

const HourlyPayChanges: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` had hourly rate changes.`}
    </Paragraph>
  );
};

const ExemptionStatusChanges: React.FC<SpecializationProps> = ({
  alert,
  schema,
}) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` changed exemption status.`}
    </Paragraph>
  );
};

const DepartmentChanges: React.FC<SpecializationProps> = ({
  alert,
  schema,
}) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` changed departments.`}
    </Paragraph>
  );
};

const StatusChanges: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` changed employment status.`}
    </Paragraph>
  );
};

const LastCompIncreaseOutliers: React.FC<SpecializationProps> = ({
  alert,
  schema,
}) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  const threshold = R.path<number>(
    ["metrics", "count", "context", "threshold"],
    alert.data
  );
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={`${schema.label}, ${threshold}% away from average`}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` whose last pay increase is more than ${Math.round(
        threshold * 100
      )}% away from the average.`}
    </Paragraph>
  );
};

const PaybandAdherance: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const above = R.path<number>(["metrics", "above", "value"], alert.data);
  const below = R.path<number>(["metrics", "below", "value"], alert.data);

  const sum = above + below;

  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={sum}
        alert={alert}
      />
      {` who are out of band.`}
    </Paragraph>
  );
};
const PayCompression: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const above = R.path<number>(["metrics", "above", "value"], alert.data);
  const below = R.path<number>(["metrics", "below", "value"], alert.data);

  const sum = above + below;

  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={sum}
        alert={alert}
      />
      {` experiencing pay compression.`}
    </Paragraph>
  );
};

const StalePayChanges: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  const threshold = R.path<number>(
    ["metrics", "count", "context", "threshold"],
    alert.data
  );
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` without a pay change in ${threshold} months.`}
    </Paragraph>
  );
};

const StalePlans: React.FC<SpecializationProps> = ({ alert, schema }) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  const threshold = R.path<number>(
    ["metrics", "count", "context", "threshold"],
    alert.data
  );
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        resource_type={ResourceType.Plan}
        resource_label="plan"
        count={count}
        alert={alert}
        view_label={`${schema.label}, not updated in ${threshold} months`}
      />

      {` which have not been updated in ${threshold} months.`}
    </Paragraph>
  );
};

const StaleTitleChanges: React.FC<SpecializationProps> = ({
  alert,
  schema,
}) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  const threshold = R.path<number>(
    ["metrics", "count", "context", "threshold"],
    alert.data
  );
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={`${schema.label}, no title change in ${threshold} months`}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` without a title change in ${threshold} months.`}
    </Paragraph>
  );
};

const LastPromotionOutliers: React.FC<SpecializationProps> = ({
  alert,
  schema,
}) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  const threshold = R.path<number>(
    ["metrics", "count", "context", "threshold"],
    alert.data
  );
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={`${schema.label}, ${threshold}% away from the average`}
        resource_type={ResourceType.Employee}
        resource_label="employee"
        count={count}
        alert={alert}
      />
      {` whose tenure is more than ${Math.round(
        threshold * 100
      )}% away from the average.`}
    </Paragraph>
  );
};

const UnmappedJobTitles: React.FC<SpecializationProps> = ({
  alert,
  schema,
}) => {
  const count = R.path<number>(["metrics", "count", "value"], alert.data);
  return (
    <Paragraph>
      <AlertName label={schema.label} />
      <ResourceCount
        view_label={schema.label}
        resource_type={ResourceType.JobTitle}
        resource_label="job title"
        count={count}
        alert={alert}
      />
      {` not mapped to a Job Family.`}
    </Paragraph>
  );
};

const Default: React.FC<SpecializationProps> = ({ schema }) => {
  return (
    <Paragraph>
      <AlertName label={schema.label} />
    </Paragraph>
  );
};

export const AlertDescription: React.FC<AlertDescriptionProps> = ({
  alert,
}) => {
  const Component = R.propOr(Default, alert.rule_id, {
    [RuleID.CompaRatioOutliers]: CompaRatioOutliers,
    [RuleID.DepartmentChange]: DepartmentChanges,
    [RuleID.ExemptionStatusChange]: ExemptionStatusChanges,
    [RuleID.FlightRisk]: FlightRisks,
    [RuleID.GenderPayComparison]: GenderPayComparison,
    [RuleID.HourlyPayChange]: HourlyPayChanges,
    [RuleID.JobTitleChange]: JobTitleChanges,
    [RuleID.LastCompIncreaseOutliers]: LastCompIncreaseOutliers,
    [RuleID.LastPromotionOutliers]: LastPromotionOutliers,
    [RuleID.ManagerChange]: ManagerChanges,
    [RuleID.MissingPaybands]: MissingPaybands,
    [RuleID.NewHires]: NewHires,
    [RuleID.PayChange]: PayChanges,
    [RuleID.OutsideOfPayBand]: PaybandAdherance,
    [RuleID.PayCompression]: PayCompression,
    [RuleID.StalePayChanges]: StalePayChanges,
    [RuleID.StalePlans]: StalePlans,
    [RuleID.StaleTitleChanges]: StaleTitleChanges,
    [RuleID.StatusChange]: StatusChanges,
    [RuleID.UnmappedJobTitles]: UnmappedJobTitles,
  }) as unknown as React.JSXElementConstructor<SpecializationProps>;

  const schema = R.prop(alert.rule_id, RuleIDSchema);

  return <Component alert={alert} schema={schema} />;
};
