import { ApplicationContext, withApplicationContext } from "context";

import * as R from "ramda";
import React from "react";

import { DashboardMetrics } from "components/Dashboard/Metrics";
import {
  BarChart,
  StackedBarChart,
  StackedBarChartData,
} from "components/graphs";
import { StandardContentArea } from "components/Layout/index";
import { Swatches } from "components/graphs/Swatches";
import { stringsToObject } from "util/array";
import { geoColors } from "components/graphs/util/scales";
import {
  Title,
  Card,
  ComponentLoader,
  Tooltip,
  SubTitle,
  NoData,
} from "components/library";
import {
  getFormat,
  getTickFormat,
  getValueFormat,
} from "components/graphs/util/format";
import { FormatFunction } from "@milio/lib/data/report/constant";
import { AlertList } from "components/Rule/AlertList";
import { BellIcon } from "@heroicons/react/outline";
import { useSignals } from "hooks/useSignals";
import { useUserSettings } from "hooks/useUserSettings";
import { useEmployeeSpend } from "hooks/useKpis";
import { RoleType, SpendData } from ".generated/models";
import { ListItem } from "components/Loaders/ListItem";
import { Schema as EmployeeSchema } from "@milio/lib/schema/employee/schema";

type Alert = any;

interface GraphData {
  alerts: Alert[];
  alertsLoading: boolean;
  geos: string[];
  spend: SpendData;
  settings: { base_currency: string };
}

interface HomePageProps {
  data: GraphData;
}

const HomePage: React.FC<HomePageProps> = ({ data }) => (
  <div className="w-full">
    <StandardContentArea>
      <div className="mb-4">
        <DashboardMetrics />
      </div>

      <div className="grid max-w-6xl lg:grid-cols-2 md:grid-cols-1 space-4 gap-4">
        <div className="col-span-2 grid lg:grid-cols-3 space-4 gap-4">
          <div className="col-span-2 h-full">
            <Card>
              <Card.Header
                title="Spend by Organization"
                action={
                  <Tooltip.Info>Does not include hourly employees</Tooltip.Info>
                }
              />
              <Card.Content>
                <div className="space-y-4">
                  <SubTitle>
                    Top organizations by total comp, broken down by geography.
                  </SubTitle>
                  <Swatches
                    scale={geoColors(data.geos)}
                    labels={stringsToObject(data.geos)}
                  />
                  {data.settings && data.spend && (
                    <StackedBarChart
                      data={
                        data.spend.by_geo as unknown as StackedBarChartData[]
                      }
                      extraProps={{
                        xAttr: "total",
                        colorScale: geoColors(data.geos),
                        xLabel: "Total Comp",
                        xValueFormatter: getValueFormat(
                          data.settings.base_currency,
                          R.prop("pay_base", EmployeeSchema.properties)
                        ),
                        xTickFormatter: getTickFormat(
                          data.settings.base_currency,
                          R.prop("pay_base", EmployeeSchema.properties)
                        ),
                      }}
                    />
                  )}
                </div>
              </Card.Content>
            </Card>
          </div>
          <div style={{ maxHeight: "34rem" }}>
            <Card>
              <Card.Content.Scrollable
                header={<Title>Signals</Title>}
                action={
                  <Tooltip.Info>
                    Up to 500 most recent events in the last two weeks.
                  </Tooltip.Info>
                }
              >
                {data.alertsLoading &&
                  R.range(0, 5).map((i) => (
                    <ListItem key={i} width={300} height={80} />
                  ))}
                {!data.alertsLoading && R.isEmpty(data.alerts) && (
                  <NoData.Message
                    Icon={BellIcon}
                    message="No events in the last two weeks."
                  />
                )}
                {!data.alertsLoading && !R.isEmpty(data.alerts) && (
                  <AlertList alerts={data.alerts} />
                )}
              </Card.Content.Scrollable>
            </Card>
          </div>
        </div>
        <div>
          <Card>
            <Card.Header
              title="Allocation by Job Title"
              action={
                <Tooltip.Info>Does not include hourly employees</Tooltip.Info>
              }
            />
            <Card.Content>
              <SubTitle>
                10 highest paid job titles by total comp allocated.
              </SubTitle>
              {data.settings && data.spend && (
                <BarChart
                  data={data.spend.by_title as any}
                  extraProps={{
                    xAttr: "total",
                    zAttr: "count",
                    xValueFormatter: getValueFormat(
                      data.settings.base_currency,
                      R.prop("pay_base", EmployeeSchema.properties)
                    ),
                    xFormat: getTickFormat(
                      data.settings.base_currency,
                      R.prop("pay_base", EmployeeSchema.properties)
                    ),
                    zFormat: getFormat(FormatFunction.WholeNumber),
                    zValueFormatter: getFormat(FormatFunction.WholeNumber),
                    xLabel: "Base Comp (sum)",
                    zLabel: "Employees",
                    yAttr: "name",
                  }}
                />
              )}
            </Card.Content>
          </Card>
        </div>
        <div>
          <Card>
            <Card.Header
              title="Employee Count by Job Title"
              action={
                <Tooltip.Info>Does not include hourly employees</Tooltip.Info>
              }
            />
            <Card.Content>
              <SubTitle>10 job titles with the most employees.</SubTitle>
              {data.settings && data.spend && (
                <BarChart
                  data={data.spend.by_count as any}
                  extraProps={{
                    xAttr: "count",
                    zAttr: "total",
                    zFormat: getTickFormat(
                      data.settings.base_currency,
                      R.prop("pay_base", EmployeeSchema.properties)
                    ),
                    xFormat: getFormat(FormatFunction.WholeNumber),
                    xValueFormatter: getFormat(FormatFunction.WholeNumber),
                    zValueFormatter: getValueFormat(
                      data.settings.base_currency,
                      R.prop("pay_base", EmployeeSchema.properties)
                    ),
                    zLabel: "Base Comp (sum)",
                    xLabel: "Employees",
                    yAttr: "name",
                  }}
                />
              )}
            </Card.Content>
          </Card>
        </div>
      </div>
    </StandardContentArea>
  </div>
);

const Container: React.FC<ApplicationContext> = () => {
  const { isLoading, alerts } = useSignals();
  const { userSettings } = useUserSettings();
  const { loading, spend } = useEmployeeSpend();

  if (loading) {
    return <ComponentLoader />;
  }

  const geos = R.uniq(R.map(R.prop("color"), spend?.by_geo || []));
  geos.sort();

  const data: GraphData = {
    alerts,
    alertsLoading: isLoading,
    geos,
    spend,
    settings: userSettings,
  };

  return <HomePage data={data} />;
};

export default withApplicationContext(Container, {
  authenticate: { roles: [RoleType.Admin, RoleType.ViewOnly] },
  title: "Dashboard",
});
