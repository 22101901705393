import * as R from "ramda";
import * as d3 from "d3";

import { createFormatter } from "lib/numberFormatter";
import { format } from "components/graphs/util";
import { FormatFunction } from "@milio/lib/data/report/constant";
import { ReportingSchema } from "@milio/lib/data/report/type";

export const getFormat = (
  f: FormatFunction,
  currency?: string
): ((value: number) => string) | Intl.NumberFormat => {
  switch (f) {
    case FormatFunction.CurrencySI:
      return format(createFormatter({ style: "currency", currency }));
    case FormatFunction.CurrencyRounded:
      return createFormatter({ style: "currency", currency });
    case FormatFunction.CurrencyDecimal:
      return createFormatter({ style: "currency", currency, digits: 2 });
    case FormatFunction.Decimal:
      return d3.format(".2~f");
    case FormatFunction.Percent:
      return d3.format(".0%");
    case FormatFunction.PercentDecimal:
      return d3.format(".2~%");
    case FormatFunction.WholeNumber:
    default:
      return d3.format("0");
  }
};

export const getTickFormat = R.curry(
  (currency: string, schema: ReportingSchema) => {
    const formatFn = R.prop("tickFormat", schema);
    return getFormat(formatFn, currency);
  }
);

export const getValueFormat = R.curry(
  (currency: string, schema: ReportingSchema) => {
    const formatFn = R.prop("valueFormat", schema);
    return getFormat(formatFn, currency);
  }
);
