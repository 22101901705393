import React from "react";

import { useApi } from "milio-api/context";
import { DataKey } from "./keys";
import { useData } from "./useData";
import { KpiApi } from "../.generated/apis";

export const useKpis = () => {
  const { api } = useApi(KpiApi);

  const fetcher = React.useCallback(() => {
    return api.findAllKpis();
  }, []);

  const { data, loading, error, mutate } = useData(DataKey.KPI, fetcher);

  return { api, kpis: data || [], loading, error, mutate };
};

export const useEmployeeSpend = () => {
  const { api } = useApi(KpiApi);

  const fetcher = React.useCallback(() => {
    return api.getSpend();
  }, []);

  const { data, loading, error, mutate } = useData(DataKey.KPISpend, fetcher);

  return { api, spend: data, loading, error, mutate };
};
