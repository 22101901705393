export const DATE_TIME_FORMAT = "MM/dd/yyyy hh:mm a";
export const DATE_TIME_TIMEZONE_FORMAT = "MM/dd/yyyy hh:mm a ZZZZ";
export const COMPACT_FULL_DATE_FORMAT = "MMM d, y";
export const DEFAULT_DATE_FORMAT = "MM/dd/yyyy";
export const TERSE_DATE_FORMAT = "MMM d";

import * as R from "ramda";
import { DateTime, Duration } from "luxon";

const STORAGE_ZONE = "UTC";

export interface DateRange {
  end?: DateTime | undefined;
  start?: DateTime | undefined;
}

export const isInDateRange = R.curry(
  (range: DateRange, date: DateTime): boolean => {
    if (range.start && range.end) {
      return date > range.start && date < range.end;
    } else if (range.start && !range.end) {
      return date > range.start;
    } else if (range.end && !range.start) {
      return date < range.end;
    }

    return true;
  }
);

export function monthsToDistance(amount: number): string {
  const end: DateTime = DateTime.now();
  const start: DateTime = end.minus({ months: amount });
  const exact: Duration = end.diff(start, ["years", "months"]);
  const duration: Duration = Duration.fromObject({
    years: Math.round(exact.years),
    months: Math.round(exact.months),
  });

  const { months } = duration;

  // Employee has been active for less than a year.
  if (exact.years < 1) {
    // Employee has been active for less than a month.
    if (exact.months < 1) {
      return "< 1m";
    }

    return duration.toFormat("M'm'");
  }

  // Don't shows months until one has been completed.
  if (months < 1) {
    return duration.toFormat("y'y'");
  }

  return duration.toFormat("y'y' M'm'");
}

/**
 * Convert a storated UTC string to a DateTime instance.
 */
export function stringToDateTime(input: string): DateTime {
  return stringToDateTimeWithZone(input);
}

function stringToDateTimeWithZone(
  input: string,
  zone: string = STORAGE_ZONE
): DateTime {
  return DateTime.fromISO(input, {
    zone,
  }).toLocal();
}

/**
 * Takes a date time string or DateTime instance and returns a standard UTC
 * date time instance.
 */
export function toDateTime(input: string | DateTime | unknown): DateTime {
  if (R.is(String, input)) {
    if ((input as string).length === 10) {
      // force interpret 2000-01-01 as local time, to avoid being off by one day
      // If there's a time attached, we can assume UTC,
      // but if we just get a date from backend we want it to be the same date for the end user
      return stringToDateTimeWithZone(input as string, "local");
    }
    return stringToDateTime(input as string);
  } else if (DateTime.isDateTime(input as Record<string, unknown>)) {
    return (input as DateTime).setZone(STORAGE_ZONE);
  }

  throw new Error("Could not parse date");
}

/**
 * Takes a date time string or DateTime instance and returns a string for
 * display purposes. If the date turns out to be invalid then null will be
 * returned and it is up to the calling code to deal with the issue.
 */
export function displayDate(
  input: string | DateTime,
  {
    format = DEFAULT_DATE_FORMAT,
  }: {
    format?: string;
  } = {}
): string | null {
  const date: DateTime = toDateTime(input);

  if (!date.isValid) {
    return null;
  }

  return new Intl.DateTimeFormat(navigator.language).format(date.toJSDate());
}
