import React from "react";

import { useData } from "hooks/useData";
import { DataKey } from "hooks/keys";
import { useApi } from "milio-api/context";
import { UserSettingApi } from ".generated/apis";

export const useUserSettings = () => {
  const { api } = useApi(UserSettingApi);

  const fetcher = React.useCallback(() => {
    return api.findUserSettings();
  }, []);

  const { data, loading, error, mutate } = useData(
    DataKey.UserSettings,
    fetcher
  );

  return { api, userSettings: data, loading, error, mutate };
};
