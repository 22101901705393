import React from "react";
import { Card, ComponentLoader } from "components/library";
import { KPIItem, KPIList, OccupyKPIList } from "components/Metric";
import { useKpis } from "hooks/useKpis";
import { KPI } from ".generated/models";

export interface DashboardMetricsProps {}

export const DashboardMetrics: React.FC<DashboardMetricsProps> = ({}) => {
  const { loading, kpis } = useKpis();
  if (loading) {
    return <ComponentLoader occupy={<OccupyKPIList />} />;
  }

  return (
    <Card>
      <Card.Content>
        <KPIList>
          {kpis?.map((kpi: KPI, i: number) => {
            return <KPIItem key={i} kpi={kpi} />;
          })}
        </KPIList>
      </Card.Content>
    </Card>
  );
};
