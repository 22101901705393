import * as R from "ramda";
import * as d3 from "d3";
import React from "react";

import { SubTitle } from "components/library/SubTitle";
import { labelize } from "util/format";
import cn from "lib/cn";
import { TruncatedTooltip } from "components/library/Tooltip/Truncated";
import { Swatch } from "components/library";

type SwatchState = Record<string, boolean>;

interface SwatchesProps {
  labels: { [key: string]: string };
  scale: d3.ScaleOrdinal<string, unknown | string>;
  onClick?: (swatchState: SwatchState) => void;
}

interface SwatchItemProps {
  className: string;
  key: number;
  color: string;
  label: string;
  isDisabled?: boolean;
  onClick?: (label: string) => void;
}

export const SwatchItem: React.FC<SwatchItemProps> = ({
  className,
  key,
  color,
  label,
  onClick = R.always(undefined),
  isDisabled = false,
}) => (
  <div
    className={cn(className, "flex items-center space-x-2 pr-2")}
    onClick={() => onClick(label)}
  >
    <Swatch.Color isDisabled={isDisabled} color={color} />

    <TruncatedTooltip tooltip={label}>
      <SubTitle key={key}>{label}</SubTitle>
    </TruncatedTooltip>
  </div>
);

export const SwatchList: React.FC<SwatchesProps> = ({
  scale,
  labels,
  onClick,
}) => {
  const [allLabels, setAllLabels] = React.useState(labels);
  const [swatchState, setSwatchState] = React.useState<SwatchState>(
    R.mapObjIndexed(R.always(true), labels)
  );

  React.useEffect(() => {
    const lower = R.pipe(
      R.toPairs,
      R.map(
        R.over(R.lensIndex(0) as R.Lens<[string, unknown], string>, R.toLower)
      ),
      R.fromPairs
    )(labels) as Record<string, string>;
    if (!R.equals(lower, allLabels)) {
      setAllLabels(lower);
      setSwatchState(R.mapObjIndexed(R.always(true), lower));
    }
  }, [labels]);

  const onSwatchClick = React.useCallback(
    (next: string) => {
      const lower = next.toLowerCase();
      if (onClick) {
        const nextState = R.propEq(lower, true, swatchState)
          ? R.set(R.lensProp(lower), false, swatchState)
          : R.set(R.lensProp(lower), true, swatchState);
        if (
          R.pipe(
            R.filter(R.identity as (val: unknown) => boolean),
            R.keys,
            R.length
          )(nextState) > 0
        ) {
          onClick(nextState);
          setSwatchState(nextState);
        }
      }
    },
    [swatchState]
  );

  return (
    <>
      {scale.domain().map((value: string, index: number) => {
        if (!value) {
          return null;
        }
        const lower = value.toLowerCase();
        return (
          R.has(lower, allLabels) && (
            <SwatchItem
              className="select-none cursor-pointer"
              isDisabled={R.propEq(lower, false, swatchState)}
              onClick={onSwatchClick}
              key={index}
              color={scale(value) as string}
              label={labelize(R.propOr("No value", lower, allLabels))}
            />
          )
        );
      })}
    </>
  );
};

export const Swatches: React.FC<SwatchesProps> = ({
  scale,
  labels,
  onClick,
}) => {
  return (
    <div className="flex flex-wrap items-center">
      <SwatchList scale={scale} labels={labels} onClick={onClick} />
    </div>
  );
};
