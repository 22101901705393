type Alert = any;
import { RuleID } from "@milio/lib/data/rule/constant";
import * as R from "ramda";
import React from "react";

import * as Style from "style";
import {
  BriefcaseIcon,
  CashIcon,
  ChartBarIcon,
  ClockIcon,
  LightBulbIcon,
  LogoutIcon,
  OfficeBuildingIcon,
  PauseIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  StarIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { Flight } from "components/Icon";

interface AlertIconProps {
  alert: Alert;
}

export const AlertIconImage: React.FC<AlertIconProps> = ({ alert }) => {
  const Component = R.propOr(LightBulbIcon, alert.rule_id, {
    [RuleID.GenderPayComparison]: ScaleIcon,
    [RuleID.OutsideOfPayBand]: ChartBarIcon,
    [RuleID.PayCompression]: ScaleIcon,
    [RuleID.MissingPaybands]: QuestionMarkCircleIcon,
    [RuleID.UnmappedJobTitles]: QuestionMarkCircleIcon,
    [RuleID.StalePayChanges]: ClockIcon,
    [RuleID.StalePlans]: ClockIcon,
    [RuleID.StaleTitleChanges]: ClockIcon,
    [RuleID.HourlyPayChange]: CashIcon,
    [RuleID.PayChange]: CashIcon,
    [RuleID.NewHires]: StarIcon,
    [RuleID.JobTitleChange]: BriefcaseIcon,
    [RuleID.ManagerChange]: UserIcon,
    [RuleID.StatusChange]: PauseIcon,
    [RuleID.FlightRisk]: Flight,
    [RuleID.DepartmentChange]: OfficeBuildingIcon,
  }) as React.JSXElementConstructor<{}>;

  return <Component />;
};

export const AlertIcon: React.FC<AlertIconProps> = ({ alert }) => {
  const background = R.propOr(Style.Color.Chart.CornflowerBlue, alert.rule_id, {
    [RuleID.PayCompression]: Style.Color.Chart.Mandy,
    [RuleID.FlightRisk]: Style.Color.Chart.Mandy,
    [RuleID.OutsideOfPayBand]: Style.Color.Chart.EnergyYellow,
    [RuleID.StatusChange]: Style.Color.Chart.Mandy,
    [RuleID.PayChange]: Style.Color.Chart.Fern,
    [RuleID.HourlyPayChange]: Style.Color.Chart.Fern,
    [RuleID.StaleTitleChanges]: Style.Color.Chart.Mandy,
    [RuleID.StalePayChanges]: Style.Color.Chart.Mandy,
    [RuleID.NewHires]: Style.Color.Chart.Saffron,
  }) as unknown as string;

  return (
    <span
      style={{ background }}
      className={
        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
      }
    >
      <div className="h-5 w-5 text-white">
        <AlertIconImage alert={alert} />
      </div>
    </span>
  );
};
