import React from "react";
import * as R from "ramda";

import { FeedList, FeedListItem, Paragraph } from "components/library";
import { COMPACT_FULL_DATE_FORMAT, displayDate } from "@milio/lib/util/date";
import { AlertDescription } from "./AlertDescription";
import { AlertIcon } from "./AlertIcon";

type Alert = any;

interface AlertListProps {
  alerts: Alert[];
}

interface AlertListItemProps {
  alert: Alert;
}

export const AlertListItem: React.FC<AlertListItemProps> = ({ alert }) => {
  return (
    <>
      <div>
        <AlertIcon alert={alert} />
      </div>
      <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4 text-sm text-gray-600">
        <AlertDescription alert={alert} />
      </div>

      <div className="text-right whitespace-nowrap flex items-center">
        <Paragraph>
          {displayDate(alert.collected_at, {
            format: COMPACT_FULL_DATE_FORMAT,
          })}
        </Paragraph>
      </div>
    </>
  );
};

export const AlertList: React.FC<AlertListProps> = ({ alerts }) => {
  return (
    <FeedList>
      {(alerts || []).map((alert: Alert, index: number) => {
        return (
          <FeedListItem
            key={`${alert.rule_id}.${alert.collected_at}`}
            isLast={R.length(alerts) - 1 === index}
          >
            <AlertListItem alert={alert} />
          </FeedListItem>
        );
      })}
    </FeedList>
  );
};
