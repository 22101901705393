import React from "react";
import ContentLoader from "react-content-loader";

export interface ListItemLoaderProps {
  width: number;
  height: number;
}

export const ListItem = ({ width, height }: ListItemLoaderProps) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 -${height / 4} ${width} ${height}`}
    backgroundColor="#FBFBFB"
    foregroundColor="#E5E5E5"
  >
    <rect x="48" y="0" rx="3" ry="3" width="192" height="6" />
    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>
);
