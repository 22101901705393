import * as R from "ramda";
import React from "react";
import cn from "lib/cn";
import styled from "styled-components";
import {
  UserIcon,
  ChartSquareBarIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline";
import * as Style from "style";
import {
  KPI,
  KPIKind,
  KPIStatus,
  KPIDataType,
  TrendDirection,
  TrendSentiment,
} from ".generated/models";
import { Label, Link, SubTitle, Heading, NoData } from "components/library";
import { MetricTrendlines } from "components/graphs/MetricTrendlines";
import { createFormatter } from "lib/numberFormatter";
import { displayPercent } from "util/format";

export interface KPIItemProps {
  kpi: KPI;
}

export interface KPITrendProps {
  change: KPI["change"];
  direction: KPI["direction"];
  sentiment: KPI["sentiment"];
}

export interface KPIValueProps {
  context: KPI["context"];
  initial: KPI["initial"];
  timeframe: KPI["timeframe"];
  type: KPI["type"];
  value: KPI["value"];
}

const ArrowUpRightIcon = ({ ...props }) => {
  return (
    <span className="transform rotate-45 translate-y-1">
      <ArrowUpIcon {...props} />
    </span>
  );
};

const ArrowDownRightIcon = ({ ...props }) => {
  return (
    <span className="transform -rotate-45">
      <ArrowDownIcon {...props} />
    </span>
  );
};

function trendDirectionToIcon(
  direction: TrendDirection
): React.FC<Record<string, unknown>> {
  return R.propOr(ArrowRightIcon, direction, {
    [TrendDirection.Decrease]: ArrowDownRightIcon,
    [TrendDirection.Flat]: ArrowRightIcon,
    [TrendDirection.Increase]: ArrowUpRightIcon,
  });
}

function trendSentimentToColor(sentiment: TrendSentiment): string {
  return R.propOr("gray", sentiment, {
    [TrendSentiment.Negative]: "red",
    [TrendSentiment.Neutral]: "gray",
    [TrendSentiment.Positive]: "green",
  });
}

export const KPITrend: React.FC<KPITrendProps> = ({
  direction,
  sentiment,
  change,
}) => {
  const Icon: React.FC<Record<string, unknown>> =
    trendDirectionToIcon(direction);
  const color: string = trendSentimentToColor(sentiment);
  const backgroundColor = `bg-${color}-100`;
  const textColor = `text-${color}-800`;
  const iconColor = `text-${color}-500`;
  // Putting the whole string here so it's not removed by PurgeCSS
  // text-green-800 bg-green-100
  // text-red-800 bg-red-100

  return (
    <div
      className={cn(
        "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium",
        backgroundColor,
        textColor
      )}
    >
      <Icon
        className={cn(
          "-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 text-primary",
          iconColor
        )}
      />
      {displayPercent(change)}
    </div>
  );
};

function formatKPIValue({ type, value, context }): string {
  switch (type) {
    case KPIDataType.Currency:
      const formatter = createFormatter({
        style: "currency",
        currency: context.currency.currency,
      });

      return formatter.format(value);
    case KPIDataType.Number:
    default:
      return createFormatter({
        style: "decimal",
      }).format(value);
  }
}

export const KPIValue: React.FC<KPIValueProps> = ({ context, type, value }) => {
  const valueFormatted: string = formatKPIValue({ type, value, context });
  const total: number | undefined = context.total?.total;
  const formatter = createFormatter({
    style: "decimal",
  });

  return (
    <>
      {valueFormatted}
      {total && (
        <SubTitle>
          {" "}
          of {formatter.format(total)} ({((100 * value) / total).toFixed(2)}
          %)
        </SubTitle>
      )}
    </>
  );
};

const SpecializedKPIContent: React.FC<KPIItemProps> = ({ kpi }) => {
  if (kpi.status === KPIStatus.NotReady) {
    return (
      <div className="w-full">
        <NoData.Message
          Icon={ChartSquareBarIcon}
          message="We haven't collected enough data yet. Check back within 24 hours."
        />
      </div>
    );
  }

  if (([KPIKind.AboveBand, KPIKind.BelowBand] as KPIKind[]).includes(kpi.id)) {
    if (kpi.status === KPIStatus.NeedsInput) {
      return (
        <NoData>
          <Link href="/plan">Create comp plans.</Link>
        </NoData>
      );
    }

    if (kpi.value === 0) {
      const direction: string =
        kpi.id === KPIKind.BelowBand ? "below" : "above";

      return (
        <NoData Icon={UserIcon}>
          <p>
            <span>No employees are {direction} band.</span>
          </p>
        </NoData>
      );
    }
  }

  if (kpi.status === KPIStatus.Ready) {
    return (
      <>
        <div className="flex justify-between items-baseline">
          <Heading.Metric>
            <KPIValue
              context={kpi.context}
              initial={kpi.initial}
              type={kpi.type}
              value={kpi.value}
              timeframe={kpi.timeframe}
            />
          </Heading.Metric>
          <div className="w-16">
            <MetricTrendlines data={kpi.samples || []} />
          </div>
        </div>
      </>
    );
  }

  return null;
};

export const KPIItemContainer = styled.dl``;

export const KPIItem: React.FC<KPIItemProps> = ({ kpi }) => {
  return (
    <KPIItemContainer>
      <Label>{kpi.name}</Label>
      <SpecializedKPIContent kpi={kpi} />
    </KPIItemContainer>
  );
};

export interface KPIListProps {
  children: React.ReactNode;
}

export const KPIListContainer = styled.dl`
  column-gap: ${Style.Layout.Padding.Large};
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

export const KPIList: React.FC<KPIListProps> = ({ children }) => {
  return <KPIListContainer>{children}</KPIListContainer>;
};

export const OccupyKPIList: React.FC<Record<string, unknown>> = ({}) => {
  const kpi: KPI = {
    id: "" as KPIKind,
    change: 0,
    direction: TrendDirection.Increase,
    initial: 0,
    name: "Empty",
    samples: [],
    sentiment: TrendSentiment.Positive,
    status: KPIStatus.Ready,
    timeframe: { start: "", end: "" },
    type: KPIDataType.Number,
    value: 0,
    context: {},
  };

  return (
    <KPIList>
      <KPIItem kpi={kpi} />
    </KPIList>
  );
};
